// import "./assets/vendor/fonts/tabler-icons.css";
// import "./assets/vendor/css/rtl/core.css";
// import "./assets/vendor/css/rtl/theme-default.css";
// import "./assets/css/demo.css";
// import "./assets/vendor/libs/node-waves/node-waves.css";
// import "./assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css";
// import "./assets/vendor/js/helpers.js";
// import "./assets/js/config.js";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/custom";

import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Default from "./pages/common/Default";
import User from "./pages/User";
import Login from "./pages/Login";
import { RequireAuth, WithOutAuth } from "./services/Middleware";
import ForgetPassword from "./pages/ForgetPassword";
import OTP from "./pages/OTP";
import PasswordChange from "./pages/PasswordChange";

function App() {
  return (<>
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<WithOutAuth><Login /></WithOutAuth>} />
        <Route path="/forget-password" element={<WithOutAuth><ForgetPassword /></WithOutAuth>} />
        <Route path="/otp" element={<WithOutAuth><OTP /></WithOutAuth>} />
        <Route path="/password-change" element={<WithOutAuth><PasswordChange /></WithOutAuth>} />

        {/* ////// Auth Routes //////// */}
        <Route path="/" element={<RequireAuth><Default /></RequireAuth>} >
          <Route index element={<RequireAuth><Dashboard /></RequireAuth>} />
          <Route path="/users" element={<RequireAuth><User /></RequireAuth>} />
        </Route>
        
      </Routes>
    </BrowserRouter>
  </>);
}

export default App;
