import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ApiService from "../services/ApiService";
import { useSelector } from "react-redux";
import { Modal, Form } from "react-bootstrap";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Country } from "country-state-city";
import { CountryList, SocialMediaList } from "../utils/Constant";
import Select from "react-select";
import { Link } from "react-router-dom";

import Swal from "sweetalert2";
import { toast } from "react-toastify";

import LiveClock from "../components/LiveClock";

const Schema = yup.object({
  username: yup.string().trim().required("Username required"),
  email: yup.string().trim().email().required("Email required"),
  country: yup.string().required("Country required"),
  password: yup.string().required("password required"),
  password_confirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Confirm Passwords must match"),
});

const EditSchema = yup.object({
  id: yup.string().trim().required("id required"),
  username: yup.string().trim().required("Username required"),
  email: yup.string().trim().email().required("Email required"),
  country: yup.string().required("Country required"),
});

export default function User() {
  const { token } = useSelector((state) => state.Auth);
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [FilterCountry, setFilterCountry] = useState(null);
  const [FilterSocialMedia, setFilterSocialMedia] = useState(null);

  const [countries, setCountries] = useState(CountryList);
  const [SocialMedia, setSocialMedia] = useState(SocialMediaList);

  const [AddModal, setAddModal] = useState(false);
  const [SelectedCountry, setSelectedCountry] = useState("");

  const [EditModal, setEditModal] = useState(false);
  const [SelectedEditCountry, setSelectedEditCountry] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    getFieldState,
  } = useForm({
    resolver: yupResolver(Schema),
  });

  const {
    register: edit_register,
    handleSubmit: edit_handleSubmit,
    formState: { errors: edit_errors },
    reset: edit_reset,
    setValue: edit_setValue,
    getValues: edit_getValues,
  } = useForm({
    resolver: yupResolver(EditSchema),
  });

  const columns = [
    {
      name: "NAME",
      selector: (row) => (
        <>
          <div className="d-flex justify-content-start align-items-center user-name">
            <div className="avatar-wrapper">
              <div className="avatar me-2">
                <img src={row.image} alt="Avatar" className="rounded-circle" />
              </div>
            </div>
            <div className="d-flex flex-column">
              <span className="emp_name text-truncate">{row.username}</span>
            </div>
          </div>
        </>
      ),
      sortable: true,
    },
    {
      name: "EMAIL",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "COUNTRY",
      selector: (row) => row.country, // row.country && fetchCountryName(row.country),
      sortable: true,
    },
    {
      name: "SOCIAL MEDIA",
      selector: (row) => (
        <>
          {row.social_media === "Whatsapp" && (
            <span className="badge  wp bg-label-success">
              {row.social_media}
            </span>
          )}
          {row.social_media === "Twitter" && (
            <span className="badge  twitr bg-label-info">
              {row.social_media}
            </span>
          )}
          {row.social_media === "Facebook" && (
            <span className="badge  facebk bg-label-warning">
              {row.social_media}
            </span>
          )}
          {row.social_media === "Pinterest" && (
            <span className="badge   pinter bg-label-danger">
              {row.social_media}
            </span>
          )}
        </>
      ),
      sortable: true,
    },
    {
      name: "AMOUNT",
      selector: (row) => (
        <>
          <span className="badge  twitr bg-label-primary">
            {row.currency} {row.total_point}
          </span>
        </>
      ),
      sortable: true,
    },
    {
      name: "REFERALS",

      selector: (row) => (
        <span className=" badge  text-bg-info">
          {row.referal_count}
        </span>
      ),
      sortable: true,
    },
    {
      name: "CLICKS",
      selector: (row) => (
        <span className="badge text-bg-success">
          {row.click_count}
        </span>
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (row) => (
        <>
          {/* /// menu button */}
          <div className="d-inline-block">
            <button
              type="button"
              className="btn btn-sm btn-icon dropdown-toggle hide-arrow"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ boxShadow: "none" }}
            >
              <i className="text-primary ti ti-dots-vertical" />
            </button>
            <ul className="dropdown-menu dropdown-menu-end m-0" style={{}}>
              <li>
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={() => copyToClipboard(row.invite_link)}
                >
                  Copy Refer Link
                </button>
              </li>
              <div className="dropdown-divider" />
              <li>
                <button
                  type="button"
                  onClick={() => handleDeleteAction(row.id)}
                  className="dropdown-item text-danger delete-record"
                >
                  Delete
                </button>
              </li>
            </ul>
          </div>

          {/* /// edit button */}
          <button
            onClick={() => handleEditOpen(row)}
            style={{
              border: "none",
              backgroundColor: "transparent",
            }}
          >
            <i className="text-primary ti ti-pencil" />
          </button>
        </>
      ),
      ignoreRowClick: true, // Prevent row click event
      allowOverflow: true,
    },
  ];

  const fetchCountryName = (iso2) => {
    const countryByIso2 = Country.getCountryByCode(iso2);
    return countryByIso2.name;
  };

  const handleChange = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const {
    data: userData,
    refetch: userDataRefetch,
    isPending,
  } = useQuery({
    queryKey: ["userData", page, perPage, FilterCountry, FilterSocialMedia],
    queryFn: async () => {
      // console.log(FilterCountry)
      var country = FilterCountry !== null ? FilterCountry : "";
      var social_media = FilterSocialMedia !== null ? FilterSocialMedia : "";
      // var country = FilterCountry !== null ? FilterCountry.value : "";
      const response = await new ApiService(token).GET(
        `/user?page=${page}&perPage=${perPage}&country=${country}&social_media=${social_media}`
      );
      if (response.data.status) {
        return response.data;
      } else {
        return response.data;
      }
    },
  });

  const handleDeleteAction = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        var response = await new ApiService(token).DELETE(`/user?id=${id}`);
        // console.log("delete res ", response);

        if (response.data.status) {
          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success",
          });
        }

        userDataRefetch();
      }
    });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);

    toast.info("Copied!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: "Bounce",
    });
  };

  //////////// Add /////////////////
  const handleAddClose = () => {
    setAddModal(false);
    setSelectedCountry("");
    reset();
  };

  const handleAddOpen = () => {
    setSelectedCountry("");
    reset();
    setAddModal(true);
  };

  const fetchCountry = () => {
    const countryList = Country.getAllCountries();
    const options = countryList.map((item) => ({
      value: item.isoCode,
      label: item.name,
    }));
    setCountries(options);

    console.log(options);
  };

  const ChooseCountry = (data) => {
    setSelectedCountry(data);
    // console.log(data)
    setValue("country", data.value);
  };

  const addNewUser = async (data) => {
    console.log(data);

    // return;
    const response = await new ApiService(token).POST("/user", data);

    console.log(response.data);

    if (response.data.status === true) {
      Swal.fire({
        title: "New User Add Successfully",
        icon: "success",
      });
    } else if (response.data.status === false) {
      if (response.data.errors) {
        for (let key in response.data.errors) {
          Swal.fire({
            title: response.data.errors[key][0],
            icon: "warning",
          });
          break;
        }
      } else {
        Swal.fire({
          title: response.data.msg,
          icon: "warning",
        });
      }
    }

    reset();
    userDataRefetch();
    setSelectedCountry("");
    setAddModal(false);
  };

  //////////// Edit /////////////////
  const handleEditClose = () => {
    setEditModal(false);
    setSelectedEditCountry("");
    edit_reset();
  };

  const handleEditOpen = (data) => {
    // const countryList = Country.getAllCountries();
    // const options = countryList.map(item => ({ value: item.isoCode, label: item.name }));

    // setCountries(options);

    // console.log(data)
    edit_setValue("id", data.id);
    edit_setValue("username", data.username);
    edit_setValue("email", data.email);
    edit_setValue("country", data.country);
    edit_setValue("social_media", data.social_media);
    setSelectedEditCountry(data.country);

    // const countryByIso2 = Country.getCountryByCode(data.country);
    // // console.log("countryByIso2",countryByIso2);
    // setSelectedEditCountry({ value: countryByIso2.isoCode, label: countryByIso2.name });

    setEditModal(true);
  };

  const ChooseEditCountry = (data) => {
    setSelectedEditCountry(data);
    // console.log(data)
    edit_setValue("country", data.value);
  };

  const editNewUser = async (data) => {
    console.log(data);

    // return;

    const response = await new ApiService(token).PUT("/user", data);

    console.log(response.data);

    if (response.data.status === true) {
      Swal.fire({
        title: "User Update Successfully",
        icon: "success",
      });
    } else if (response.data.status === false) {
      if (response.data.errors) {
        for (let key in response.data.errors) {
          Swal.fire({
            title: response.data.errors[key][0],
            icon: "warning",
          });
          break;
        }
      } else {
        Swal.fire({
          title: response.data.msg,
          icon: "warning",
        });
      }
    }

    edit_reset();
    userDataRefetch();
    setSelectedEditCountry("");
    setEditModal(false);
  };

  useEffect(() => {
    // fetchCountry();
  }, []);

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        {/* <h4 className="py-3 mb-4"><span className="text-muted fw-light">DataTables /</span> Basic</h4> */}

        <h4 className="py-3 mb-4">
          <LiveClock country={FilterCountry} />
        </h4>

        <div className="card">
          <div className="card-datatable table-responsive pt-0">
            {/* <table className="datatables-basic table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>id</th>
                                <th>Name</th>
                            </tr>
                        </thead>
                    </table> */}
            <DataTable
              title={
                <div className="row mt-4">
                  <div className="col-2">Users Table</div>
                  <div className="col-4">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setFilterCountry(e.target.value)}
                    >
                      <option value={""}>SELECT COUNTRY</option>
                      {countries.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div className="col-4">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setFilterSocialMedia(e.target.value)}
                    >
                      <option value={""}>SELECT SOCIAL MEDIA</option>
                      {SocialMediaList.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div
                    className="col-2"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <button
                      className="btn btn-secondary create-new btn-primary waves-effect waves-light"
                      tabIndex={0}
                      aria-controls="DataTables_Table_0"
                      type="button"
                      onClick={handleAddOpen}
                    >
                      <span>
                        <i className="ti ti-plus me-sm-1" />{" "}
                        <span className="d-none d-sm-inline-block">
                          Add New User
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              }
              columns={columns}
              data={userData?.data?.data ? userData?.data?.data : []}
              selectableRows={true}
              onSelectedRowsChange={handleChange}
              progressPending={isPending}
              pagination={true}
              paginationServer
              paginationPerPage={perPage}
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              paginationTotalRows={userData?.data?.total}
              onChangePage={(page) => setPage(page)}
              onChangeRowsPerPage={(perPage) => setPerPage(perPage)}
              className="custom-data_table"
            />
          </div>
        </div>

        {/* Add User Modal to add new record */}
        <Modal
          className="offcanvas offcanvas-end"
          show={AddModal}
          onHide={handleAddClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="offcanvas-header border-bottom">
            <Modal.Title className="offcanvas-title">
              <h5 className="offcanvas-title" id="exampleModalLabel">
                New Record
              </h5>
            </Modal.Title>
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={handleAddClose}
            />
          </Modal.Header>
          <Modal.Body>
            <div className="offcanvas-body flex-grow-1">
              <Form
                className="add-new-record pt-0 row g-2"
                onSubmit={handleSubmit(addNewUser)}
              >
                <div className="col-sm-12">
                  <Form.Group>
                    <label className="form-label" htmlFor="basicFullname">
                      Username
                    </label>
                    <div
                      className={`input-group input-group-merge ${
                        !!errors.username && "is-invalid-2"
                      }`}
                    >
                      <span id="basicFullname2" className="input-group-text">
                        <i className="ti ti-user" />
                      </span>
                      <Form.Control
                        type="text"
                        className="dt-full-name"
                        placeholder="John Doe"
                        {...register("username")}
                        isInvalid={!!errors.username}
                      />
                    </div>

                    <Form.Control.Feedback type="invalid">
                      {errors.username?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-sm-12">
                  <Form.Group>
                    <label className="form-label" htmlFor="basicEmail">
                      Email
                    </label>
                    <div
                      className={`input-group input-group-merge ${
                        !!errors.email && "is-invalid-2"
                      }`}
                    >
                      <span className="input-group-text">
                        <i className="ti ti-mail" />
                      </span>
                      <Form.Control
                        type="email"
                        className="dt-email"
                        placeholder="john.doe@example.com"
                        {...register("email")}
                        isInvalid={!!errors.email}
                      />
                    </div>
                    <div className="form-text">
                      You can use letters, numbers &amp; periods
                    </div>

                    <Form.Control.Feedback type="invalid">
                      {errors.email?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-sm-12">
                  <Form.Group>
                    <label className="form-label" htmlFor="basicSalary">
                      Country{" "}
                      {SelectedCountry !== "" && (
                        <>
                          (<LiveClock country={SelectedCountry} />)
                        </>
                      )}
                    </label>
                    <div
                      className={`input-group input-group-merge ${
                        !!errors.country && "is-invalid-2"
                      }`}
                    >
                      {/* <Select
                                            options={countries}
                                            onChange={(selectedOption) => ChooseCountry(selectedOption)}
                                            value={SelectedCountry}
                                            className='form-control'
                                        /> */}
                      <Form.Select
                        {...register("country")}
                        isInvalid={!!errors.country}
                        onChange={(e) => setSelectedCountry(e.target.value)}
                      >
                        <option value={""}>select country</option>
                        {countries.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </Form.Select>
                    </div>

                    <Form.Control.Feedback type="invalid">
                      {errors.country?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-sm-12">
                  <Form.Group>
                    <label className="form-label" htmlFor="basicSalary">
                      Social SocialMedia
                    </label>
                    <div
                      className={`input-group input-group-merge ${
                        !!errors.social_media && "is-invalid-2"
                      }`}
                    >
                      {/* <Select
                                            options={countries}
                                            onChange={(selectedOption) => ChooseCountry(selectedOption)}
                                            value={SelectedCountry}
                                            className='form-control'
                                        /> */}
                      <Form.Select
                        {...register("social_media")}
                        isInvalid={!!errors.social_media}
                      >
                        <option value={""}>select</option>
                        {SocialMediaList.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </Form.Select>
                    </div>

                    <Form.Control.Feedback type="invalid">
                      {errors.social_media?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-sm-12">
                  <Form.Group>
                    <label className="form-label" htmlFor="basicSalary">
                      Password
                    </label>
                    <div
                      className={`input-group input-group-merge ${
                        !!errors.password && "is-invalid-2"
                      }`}
                    >
                      <span id="basicSalary2" className="input-group-text">
                        <i className="ti ti-lock" />
                      </span>
                      <Form.Control
                        type="text"
                        className="dt-salary"
                        placeholder="Password"
                        {...register("password")}
                        isInvalid={!!errors.password}
                      />
                    </div>

                    <Form.Control.Feedback type="invalid">
                      {errors.password?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-sm-12">
                  <Form.Group>
                    <label className="form-label" htmlFor="basicSalary">
                      Confirm Password
                    </label>
                    <div
                      className={`input-group input-group-merge ${
                        !!errors.password_confirm && "is-invalid-2"
                      }`}
                    >
                      <span id="basicSalary2" className="input-group-text">
                        <i className="ti ti-lock" />
                      </span>
                      <Form.Control
                        type="text"
                        className="dt-salary"
                        placeholder="Confirm Password"
                        {...register("password_confirm")}
                        isInvalid={!!errors.password_confirm}
                      />
                    </div>

                    <Form.Control.Feedback type="invalid">
                      {errors.password_confirm?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-sm-12">
                  <button
                    type="submit"
                    className="btn btn-primary me-sm-3 me-1"
                  >
                    Submit
                  </button>
                  <button
                    type="reset"
                    className="btn btn-outline-secondary"
                    data-bs-dismiss="offcanvas"
                    onClick={handleAddClose}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
        {/*/ DataTable with Buttons */}

        {/* Edit User Modal to add new record */}
        <Modal
          className="offcanvas offcanvas-end"
          show={EditModal}
          onHide={handleEditClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="offcanvas-header border-bottom">
            <Modal.Title className="offcanvas-title">
              <h5 className="offcanvas-title" id="exampleModalLabel">
                Update Record
              </h5>
            </Modal.Title>
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={handleEditClose}
            />
          </Modal.Header>
          <Modal.Body>
            <div className="offcanvas-body flex-grow-1">
              <Form
                className="add-new-record pt-0 row g-2"
                onSubmit={edit_handleSubmit(editNewUser)}
              >
                <div className="col-sm-12">
                  <Form.Group>
                    <label className="form-label" htmlFor="basicFullname">
                      Username
                    </label>
                    <div
                      className={`input-group input-group-merge ${
                        !!edit_errors.username && "is-invalid-2"
                      }`}
                    >
                      <span id="basicFullname2" className="input-group-text">
                        <i className="ti ti-user" />
                      </span>
                      <Form.Control
                        type="text"
                        className="dt-full-name"
                        placeholder="John Doe"
                        {...edit_register("username")}
                        isInvalid={!!edit_errors.username}
                      />
                    </div>

                    <Form.Control.Feedback type="invalid">
                      {edit_errors.username?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-sm-12">
                  <Form.Group>
                    <label className="form-label" htmlFor="basicEmail">
                      Email
                    </label>
                    <div
                      className={`input-group input-group-merge ${
                        !!edit_errors.email && "is-invalid-2"
                      }`}
                    >
                      <span className="input-group-text">
                        <i className="ti ti-mail" />
                      </span>
                      <Form.Control
                        type="email"
                        className="dt-email"
                        placeholder="john.doe@example.com"
                        {...edit_register("email")}
                        isInvalid={!!edit_errors.email}
                      />
                    </div>
                    <div className="form-text">
                      You can use letters, numbers &amp; periods
                    </div>

                    <Form.Control.Feedback type="invalid">
                      {edit_errors.email?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-sm-12">
                  <Form.Group>
                    <label className="form-label" htmlFor="basicSalary">
                      Country{" "}
                      {SelectedEditCountry !== "" && (
                        <>
                          (<LiveClock country={SelectedEditCountry} />)
                        </>
                      )}
                    </label>
                    <div
                      className={`input-group input-group-merge ${
                        !!edit_errors.country && "is-invalid-2"
                      }`}
                    >
                      {/* <Select
                                            options={countries}
                                            onChange={(selectedOption) => ChooseEditCountry(selectedOption)}
                                            value={SelectedEditCountry}
                                            className='form-control'
                                        /> */}
                      <Form.Select
                        {...edit_register("country")}
                        isInvalid={!!edit_errors.country}
                        onChange={(e) => setSelectedEditCountry(e.target.value)}
                      >
                        <option value={""}>select country</option>
                        {countries.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </Form.Select>
                    </div>

                    <Form.Control.Feedback type="invalid">
                      {edit_errors.country?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-sm-12">
                  <Form.Group>
                    <label className="form-label" htmlFor="basicSalary">
                      Social SocialMedia
                    </label>
                    <div
                      className={`input-group input-group-merge ${
                        !!edit_errors.social_media && "is-invalid-2"
                      }`}
                    >
                      {/* <Select
                                            options={countries}
                                            onChange={(selectedOption) => ChooseCountry(selectedOption)}
                                            value={SelectedCountry}
                                            className='form-control'
                                        /> */}
                      <Form.Select
                        {...edit_register("social_media")}
                        isInvalid={!!edit_errors.social_media}
                      >
                        <option value={""}>select</option>
                        {SocialMediaList.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </Form.Select>
                    </div>

                    <Form.Control.Feedback type="invalid">
                      {edit_errors.social_media?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                {/* <div className="col-sm-12">
                                <Form.Group>
                                    <label className="form-label" htmlFor="basicSalary">
                                        Password
                                    </label>
                                    <div className={`input-group input-group-merge ${!!errors.username && "is-invalid-2"}`}>
                                        <span id="basicSalary2" className="input-group-text">
                                            <i className="ti ti-lock" />
                                        </span>
                                        <Form.Control
                                            type="text"
                                            className="dt-salary"
                                            placeholder="Password"
                                            {...register("password")}
                                            isInvalid={!!errors.password}
                                        />
                                    </div>

                                    <Form.Control.Feedback type="invalid">
                                        {errors.password?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>


                            <div className="col-sm-12">
                                <Form.Group>
                                    <label className="form-label" htmlFor="basicSalary">
                                        Confirm Password
                                    </label>
                                    <div className={`input-group input-group-merge ${!!errors.username && "is-invalid-2"}`}>
                                        <span id="basicSalary2" className="input-group-text">
                                            <i className="ti ti-lock" />
                                        </span>
                                        <Form.Control
                                            type="text"
                                            className="dt-salary"
                                            placeholder="Confirm Password"
                                            {...register("password_confirm")}
                                            isInvalid={!!errors.password_confirm}
                                        />
                                    </div>

                                    <Form.Control.Feedback type="invalid">
                                        {errors.password_confirm?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div> */}

                <div className="col-sm-12">
                  <button
                    type="submit"
                    className="btn btn-primary me-sm-3 me-1"
                  >
                    Update
                  </button>
                  <button
                    type="reset"
                    className="btn btn-outline-secondary"
                    data-bs-dismiss="offcanvas"
                    onClick={handleEditClose}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
        {/*/ DataTable with Buttons */}
      </div>
    </>
  );
}
