import React, { useEffect, useState } from 'react';

import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup";

import { Form } from 'react-bootstrap';
import ApiService from '../services/ApiService';
import { useDispatch } from 'react-redux';
import { authActions } from "../redux/reducers/Auth";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'

const Schema = yup.object({
    email: yup.string().trim().email().required("Email required"),
});

export default function ForgetPassword() {

    const [Disable,setDisable]=useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(Schema),
    });


    const onSubmit = async (data) => {
        console.log(data)

        setDisable(true);

        var response = await new ApiService("").POST("/otp-send", data);

        console.log(response.data)

        if (response.data.status) {
            dispatch(authActions.UpdateForgetEmail(data.email));
            dispatch(authActions.show_otp_page_update(true));
            reset();
            Swal.fire({
                title: response.data.msg,
                icon: "success"
            });
            navigate("/otp");
        } else {
            Swal.fire({
                title: response.data.msg,
                icon: "error"
            });
        }

        setDisable(false);

    }



    return (<div className="container-xxl">
        <div className="authentication-wrapper authentication-basic container-p-y">
            <div className="authentication-inner py-4">
                {/* Login */}
                <div className="card">
                    <div className="card-body">
                        {/* Logo */}
                        <div className="app-brand justify-content-center mb-4 mt-2">
                            <a href="index.html" className="app-brand-link gap-2">
                                <span className="app-brand-logo demo">
                                    <svg
                                        width={32}
                                        height={22}
                                        viewBox="0 0 32 22"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z"
                                            fill="#7367F0"
                                        />
                                        <path
                                            opacity="0.06"
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z"
                                            fill="#161616"
                                        />
                                        <path
                                            opacity="0.06"
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z"
                                            fill="#161616"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z"
                                            fill="#7367F0"
                                        />
                                    </svg>
                                </span>
                                <span className="app-brand-text demo text-body fw-bold ms-1">
                                    Vuexy
                                </span>
                            </a>
                        </div>
                        {/* /Logo */}
                        <h4 className="mb-1 pt-2">Welcome to Vuexy! 👋</h4>
                        <p className="mb-4">
                            Forget Password
                        </p>
                        <Form
                            className="mb-3"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">
                                    Email
                                </label>

                                <Form.Control
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter your email"
                                    autofocus=""
                                    {...register("email")}
                                    isInvalid={!!errors.email}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors.email?.message}
                                </Form.Control.Feedback>

                            </div>

                            <div className="mb-3">
                                <button className="btn btn-primary d-grid w-100" type="submit" disabled={Disable}>
                                    Submit
                                </button>
                            </div>
                        </Form>


                    </div>
                </div>
                {/* /Register */}
            </div>
        </div>
    </div>
    )
}
