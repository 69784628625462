import React from 'react'
import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom'
import Sidebar from './Sidebar'
import Footer from './Footer'
import { useDispatch } from 'react-redux';
import { authActions } from '../../redux/reducers/Auth';

export default function Default() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const Logout=()=>{
        dispatch(authActions.Logout());
        navigate("/login");
    }

    return (<>
        {/* Layout wrapper */}
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                {/* Menu */}
                <aside
                    id="layout-menu"
                    className="layout-menu menu-vertical menu bg-menu-theme"
                >
                    <div className="app-brand demo">
                        <NavLink to="/" className="app-brand-link">
                            <span className="app-brand-logo demo">
                                <svg
                                    width={32}
                                    height={22}
                                    viewBox="0 0 32 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z"
                                        fill="#7367F0"
                                    />
                                    <path
                                        opacity="0.06"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z"
                                        fill="#161616"
                                    />
                                    <path
                                        opacity="0.06"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z"
                                        fill="#161616"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z"
                                        fill="#7367F0"
                                    />
                                </svg>
                            </span>
                            <span className="app-brand-text demo menu-text fw-bold">Vuexy</span>
                        </NavLink>
                        <Link
                            to="#"
                            className="layout-menu-toggle menu-link text-large ms-auto"
                        >
                            <i className="ti menu-toggle-icon d-none d-xl-block ti-sm align-middle" />
                            <i className="ti ti-x d-block d-xl-none ti-sm align-middle" />
                        </Link>
                    </div>
                    <div className="menu-inner-shadow" />

                    {/* //////// left menu //////// */}
                    <Sidebar />

                </aside>
                {/* / Menu */}

                {/* Layout container */}
                <div className="layout-page">
                    {/* Navbar */}
                    <nav
                        className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
                        id="layout-navbar"
                    >
                        <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
                            <Link
                                className="nav-item nav-link px-0 me-xl-4"
                                to="#"
                            >
                                <i className="ti ti-menu-2 ti-sm" />
                            </Link>
                        </div>
                        <div
                            className="navbar-nav-right d-flex align-items-center"
                            id="navbar-collapse"
                        >
                            <ul className="navbar-nav flex-row align-items-center ms-auto">
                                {/* User */}
                                <li className="nav-item navbar-dropdown dropdown-user dropdown">
                                    <Link
                                        className="nav-link dropdown-toggle hide-arrow"
                                        href="#;"
                                        data-bs-toggle="dropdown"
                                    >
                                        <div className="avatar avatar-online">
                                            <img
                                                src="/assets/img/avatars/1.png"
                                                alt=""
                                                className="h-auto rounded-circle"
                                            />
                                        </div>
                                    </Link>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li>
                                            <Link className="dropdown-item" to="#">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar avatar-online">
                                                            <img
                                                                src="/assets/img/avatars/1.png"
                                                                alt=""
                                                                className="h-auto rounded-circle"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <span className="fw-medium d-block">John Doe</span>
                                                        <small className="text-muted">Admin</small>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                        <li>
                                            <div className="dropdown-divider" />
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="#">
                                                <i className="ti ti-user-check me-2 ti-sm" />
                                                <span className="align-middle">My Profile</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="#">
                                                <i className="ti ti-settings me-2 ti-sm" />
                                                <span className="align-middle">Settings</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <div className="dropdown-divider" />
                                        </li>
                                        <li>
                                            <button type='button' className="dropdown-item" onClick={Logout}>
                                                <i className="ti ti-logout me-2 ti-sm" />
                                                <span className="align-middle">Log Out</span>
                                            </button>
                                        </li>
                                    </ul>
                                </li>
                                {/*/ User */}
                            </ul>
                        </div>
                    </nav>
                    {/* / Navbar */}

                    {/* Content wrapper */}
                    <div className="content-wrapper">

                        {/* Content */}
                        <Outlet />
                        {/* / Content */}

                        {/* Footer */}
                        <Footer />
                        {/* / Footer */}

                        <div className="content-backdrop fade" />
                    </div>
                    {/* Content wrapper */}
                </div>
                {/* / Layout page */}
            </div>
            {/* Overlay */}
            <div className="layout-overlay layout-menu-toggle" />
            {/* Drag Target Area To SlideIn Menu On Small Screens */}
            <div className="drag-target" />
        </div>
        {/* / Layout wrapper */}
    </>
    )
}
