export const CountryList = [
    { label: "Japan", value: "Japan", currency: "JPY", currency_full_name: "Japanese Yen" },
    { label: "Malaysia", value: "Malaysia", currency: "MYR", currency_full_name: "Malaysian Ringgit" },
    { label: "South Korea", value: "South Korea", currency: "KRW", currency_full_name: "South Korean Won" },
    { label: "France", value: "France", currency: "EUR", currency_full_name: "Euro" },
    { label: "Germany", value: "Germany", currency: "EUR", currency_full_name: "Euro" },
    { label: "Australia", value: "Australia", currency: "AUD", currency_full_name: "Australian Dollar" },
    { label: "Italy", value: "Italy", currency: "EUR", currency_full_name: "Euro" },
    { label: "UK", value: "UK", currency: "GBP", currency_full_name: "British Pound Sterling" },
    { label: "US", value: "US", currency: "USD", currency_full_name: "United States Dollar" },
    { label: "Spain", value: "Spain", currency: "EUR", currency_full_name: "Euro" },
    { label: "Russia", value: "Russia", currency: "RUB", currency_full_name: "Russian Ruble" },
    { label: "Brazil", value: "Brazil", currency: "BRL", currency_full_name: "Brazilian Real" },
    { label: "China", value: "China", currency: "CNY", currency_full_name: "Chinese Yuan" },
];


export const SocialMediaList = [
    { label: "Twitter", value: "Twitter" },
    { label: "Facebook", value: "Facebook" },
    { label: "Whatsapp", value: "Whatsapp" },
    { label: "Pinterest", value: "Pinterest" },
]