import axios from 'axios';
const REACT_APP_API_SERVICE_URL = process.env.REACT_APP_API_SERVICE_URL;



class ApiService {

    constructor(token) {
        this.token = token;
    }


    GET(url) {

        const header = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": 'Bearer ' + this.token,
            }
        };

        var finalUrl = REACT_APP_API_SERVICE_URL + url;

        return axios.get(finalUrl, header);
    }

    POST(url, payload, h_tag = "json") {

        const header = {
            headers: {
                "Content-Type": (h_tag === "json" ? "application/json" : "multipart/form-data"),
                "Accept": "application/json",
                "Authorization": 'Bearer ' + this.token,
            }
        };

        var finalUrl = REACT_APP_API_SERVICE_URL + url;

        return axios.post(finalUrl, payload, header);
    }


    PUT(url, payload, h_tag = "json") {

        const header = {
            headers: {
                "Content-Type": (h_tag === "json" ? "application/json" : "multipart/form-data"),
                "Accept": "application/json",
                "Authorization": 'Bearer ' + this.token,
            }
        };

        var finalUrl = REACT_APP_API_SERVICE_URL + url;

        return axios.put(finalUrl, payload, header);
    }


    DELETE(url) {

        const header = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": 'Bearer ' + this.token,
            }
        };

        var finalUrl = REACT_APP_API_SERVICE_URL + url;

        return axios.delete(finalUrl, header);
    }

}

export default ApiService;
