import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  userData:{},
  token:"",
  forget_email:"",
  show_otp_page:false,
  show_change_password_page:false,
};

const authSlice = createSlice ({
  name: 'authentication',
  initialState: initialState,
  reducers: {
    Login (state,action) {
      
      state.forget_email="";
      state.show_otp_page=false;
      state.show_change_password_page=false;

      state.isAuthenticated = true;
      state.userData=action.payload.data;
      state.token=action.payload.token;
      // state.token=action.payload.token;
      // localStorage.setItem("token",action.payload.token);
      console.log("user_data print: ",action.payload.data);
    },

    UpdateUserData (state,action) {
      state.userData=action.payload;
      console.log("updated user_data print: ",action.payload);
    },

    UpdateForgetEmail (state,action) {
      state.forget_email=action.payload;
    },

    show_otp_page_update (state,action) {
      state.show_otp_page=action.payload;
    },

    show_change_password_page_update (state,action) {
      state.show_change_password_page=action.payload;
    },


    Logout (state) {
      state.isAuthenticated = false;
      state.userData={};
      state.token="";
      state.forget_email="";
      state.show_otp_page=false;
      state.show_change_password_page=false;
      localStorage.clear ();
      sessionStorage.clear();
    },

  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;