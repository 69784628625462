import React from 'react'
import { NavLink } from 'react-router-dom'

export default function Sidebar() {
    return (<ul className="menu-inner py-1">
        {/* Page */}
        {/* <li className="menu-item active">
            <NavLink to="/" className="menu-link">
                <i className="menu-icon tf-icons ti ti-smart-home" />
                <div data-i18n="Page 1">Page 1</div>
            </NavLink>
        </li>
        <li className="menu-item">
            <NavLink to="/user" className="menu-link">
                <i className="menu-icon tf-icons ti ti-app-window" />
                <div data-i18n="Page 2">Page 2</div>
            </NavLink>
        </li> */}

        <NavLink to="/" className="menu-item">
            <li className="menu-link">
                <i className="menu-icon tf-icons ti ti-smart-home" />
                <div data-i18n="Dashboard">Dashboard</div>
            </li>
        </NavLink>
        <NavLink to="/users" className="menu-item">
            <li className="menu-link">
                <i className="menu-icon tf-icons ti ti-app-window" />
                <div data-i18n="Users">Users</div>
            </li>
        </NavLink>

    </ul>)
}
