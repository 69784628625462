import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (<footer className="content-footer footer bg-footer-theme">
        <div className="container-xxl">
            <div className="footer-container d-flex align-items-center justify-content-between py-2 flex-md-row flex-column">
                <div>
                    © 2024 , made with ❤️ by
                    <Link
                        href="https://pixinvent.com"
                        target="_blank"
                        className="footer-link text-primary fw-medium"
                    >
                        Pixinvent
                    </Link>
                </div>
                <div className="d-none d-lg-inline-block">
                    <Link
                        href="https://demos.pixinvent.com/vuexy-html-admin-template/documentation/"
                        target="_blank"
                        className="footer-link me-4"
                    >
                        Documentation
                    </Link>
                </div>
            </div>
        </div>
    </footer>)
}
