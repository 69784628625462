import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import DonutChart from '../components/DonutChart'
import { useQuery } from '@tanstack/react-query';
import ApiService from '../services/ApiService';
import { useSelector } from 'react-redux';

export default function Dashboard() {

    const { token } = useSelector(state => state.Auth);


    const { data: dashboardData } = useQuery({
        queryKey: ['dashboardData',],
        queryFn: async () => {

            const response = await new ApiService(token).GET(`/dashboard`);

            // console.log("dash....... ", response.data)
            if (response.data.status) {
                return response.data;
            } else {
                return response.data;
            }

        },
    });


    return (<>
        <div className="container-xxl chart-content flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-xl-12 col-12">
                    <div className="row">

                        {/* Total users */}
                        <div className="col-xl-5 mb-4 col-md-4 d-flex">
                            <div className="card">
                                <div className="card-body">
                                    <div className="justify-content-between">
                                        <div className="d-flex chart-title-box">
                                            <div className="card-title mb-auto chart-title">
                                                <h5 className="mb-1 text-nowrap">Total Signup</h5>
                                                {/* <small>Monthly Report</small> */}
                                            </div>
                                            <div className="chart-statistics">
                                                <h3 className="card-title mb-0">{dashboardData?.total_signup}</h3>
                                                {/* <small className="text-success text-nowrap fw-medium">
                                                    <i className="ti ti-chevron-up me-1" /> 15.8%
                                                </small> */}
                                            </div>
                                        </div>
                                        <div className="chart-and-txt mt-2">
                                            <div className="chart-detls">
                                                <div className="circle-txt">
                                                    <div className="circle-icon"></div>
                                                    <p>Total Signup ({dashboardData?.total_signup})</p>
                                                </div>
                                                <div className="circle-txt">
                                                    <div className="circle-icon" style={{ background: "#36A2EB" }}></div>
                                                    <p>Yesterday Signup ({dashboardData?.before_total_signup})</p>
                                                </div>
                                                <div className="circle-txt">
                                                    <div className="circle-icon" style={{ background: "#FFCE56" }}></div>
                                                    <p>Today Signup ({dashboardData?.today_total_signup})</p>
                                                </div>

                                            </div>
                                            <div className='donutChart'>
                                                {dashboardData?.total_signup && <DonutChart data={{
                                                    labels: [`Total Signup (${dashboardData.total_signup})`, `Yesterday Signup (${dashboardData.before_total_signup})`, `Today Signup (${dashboardData.today_total_signup})`],
                                                    datasets: [
                                                        {
                                                            data: [dashboardData.total_signup, dashboardData.before_total_signup, dashboardData.today_total_signup],
                                                            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                                                            hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
                                                        }
                                                    ]
                                                }} legend={false} width={"160px"} height={"160px"} />}
                                                {/* <div style={{ position: 'relative', width: '200px', height: '200px' }}>
                                                    <DonutChart data={data} legend={false} />
                                                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>300</div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*/ Total users */}


                        {/* Total Percentage users */}
                        <div className="col-xl-12 mb-4 col-md-4 d-flex">
                            <div className="card">
                                <div className="card-body">
                                    <div className="justify-content-between">
                                        <div className="flex-column">
                                            <div className="card-title mb-auto chart-title">
                                                <h5 className="mb-1 text-nowrap">Signup Percentage</h5>
                                                {/* <small>Monthly Report</small> */}
                                            </div>
                                            <div className="chart-statistics">
                                                {/* <h3 className="card-title mb-1">{dashboardData?.total_signup_percentage}%</h3> */}
                                                {/* <small className="text-success text-nowrap fw-medium">
                                                    <i className="ti ti-chevron-up me-1" /> 15.8%
                                                </small> */}
                                            </div>
                                        </div>
                                        <div className="chart-and-txt mt-4">
                                            <div className="chart-detls">



                                                <div className="circle-txt">
                                                    <div className="circle-icon" style={{ background: "#36A2EB" }}></div>
                                                    <p>Yesterday Signup ({dashboardData?.before_total_signup_percentage})%</p>
                                                </div>
                                                <div className="circle-txt">
                                                    <div className="circle-icon" style={{ background: "#FFCE56" }}></div>
                                                    <p>Today Signup ({dashboardData?.today_total_signup_percentage})%</p>
                                                </div>
                                            </div>
                                            <div className='donutChart'>
                                                {/* {dashboardData?.total_signup && <DonutChart data={{
                                                    labels: [`Total Signup (${dashboardData.total_signup_percentage}%)`, `Yesterday Signup (${dashboardData.before_total_signup_percentage}%)`, `Today Signup (${dashboardData.today_total_signup_percentage}%)`],
                                                    datasets: [
                                                        {
                                                            data: [dashboardData.total_signup_percentage, dashboardData.before_total_signup_percentage, dashboardData.today_total_signup_percentage],
                                                            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                                                            hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
                                                        }
                                                    ]
                                                }} legend={false} width={"160px"} height={"160px"} />} */}

                                                {/* <div style={{ position: 'relative', width: '200px', height: '200px' }}>
                                                    <DonutChart data={data} legend={false} />
                                                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>300</div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*/ Total Percentage users */}


                    </div>
                </div>
            </div>

        </div>
    </>
    )
}
