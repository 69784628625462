import React from 'react';
import Clock from 'react-live-clock';

export default function LiveClock(props) {
    return (
        <>
            {props.country === "Japan" &&
                <span>
                    Current Time Of {props.country}: <Clock format={'h:mm:ss A'} ticking={true} timezone={'Asia/Tokyo'} />
                </span>}

            {props.country === "Malaysia" &&
                <span>
                    Current Time Of {props.country}: <Clock format={'h:mm:ss A'} ticking={true} timezone={'Asia/Kuala_Lumpur'} />
                </span>}

            {props.country === "South Korea" &&
                <span>
                    Current Time Of {props.country}: <Clock format={'h:mm:ss A'} ticking={true} timezone={'Asia/Seoul'} />
                </span>}

            {props.country === "France" &&
                <span>
                    Current Time Of {props.country}: <Clock format={'h:mm:ss A'} ticking={true} timezone={'Europe/Paris'} />
                </span>}

            {props.country === "Germany" &&
                <span>
                    Current Time Of {props.country}: <Clock format={'h:mm:ss A'} ticking={true} timezone={'Europe/Berlin'} />
                </span>}

            {props.country === "Australia" &&
                <span>
                    Current Time Of {props.country}: <Clock format={'h:mm:ss A'} ticking={true} timezone={'Australia/Sydney'} />
                </span>}

            {props.country === "Italy" &&
                <span>
                    Current Time Of {props.country}: <Clock format={'h:mm:ss A'} ticking={true} timezone={'Europe/Rome'} />
                </span>}

            {props.country === "UK" &&
                <span>
                    Current Time Of {props.country}: <Clock format={'h:mm:ss A'} ticking={true} timezone={'Europe/London'} />
                </span>}

            {props.country === "US" &&
                <span>
                    Current Time Of {props.country}: <Clock format={'h:mm:ss A'} ticking={true} timezone={'US/Pacific'} />
                </span>}

            {props.country === "Spain" &&
                <span>
                    Current Time Of {props.country}: <Clock format={'h:mm:ss A'} ticking={true} timezone={'Europe/Madrid'} />
                </span>}

            {props.country === "Russia" &&
                <span>
                    Current Time Of {props.country}: <Clock format={'h:mm:ss A'} ticking={true} timezone={'Europe/Moscow'} />
                </span>}

            {props.country === "Brazil" &&
                <span>
                    Current Time Of {props.country}: <Clock format={'h:mm:ss A'} ticking={true} timezone={'America/Sao_Paulo'} />
                </span>}

            {props.country === "China" &&
                <span>
                    Current Time Of {props.country}: <Clock format={'h:mm:ss A'} ticking={true} timezone={'Asia/Shanghai'} />
                </span>}


        </>
    )
}
