import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';




// eslint-disable-next-line react/prop-types
export const RequireAuth = ({ children }) => {
    const { isAuthenticated } = useSelector(state => state.Auth);

    const location = useLocation();


    if (isAuthenticated) {

        return children;

    } else {
        localStorage.clear();
        sessionStorage.clear();

        return <Navigate to="/login" state={{ path: location.pathname }} />;
    }


};

// eslint-disable-next-line react/prop-types
export const WithOutAuth = ({ children }) => {
    const { isAuthenticated, show_otp_page, show_change_password_page, } = useSelector(state => state.Auth);

    const location = useLocation();

    console.log(location.pathname, " location.pathname")

    if (isAuthenticated) {
        return <Navigate to="/" state={{ path: location.pathname }} />;
    }

    if (location.pathname === "/otp") {
        if(show_otp_page){
            return children;
        }else{
            return <Navigate to="/login" state={{ path: location.pathname }} />;
        }

    }

    if (location.pathname === "/password-change") {
        if(show_change_password_page){
            return children;
        }else{
            return <Navigate to="/login" state={{ path: location.pathname }} />;
        }

    }

    // localStorage.clear();
    // sessionStorage.clear();

    return children;
};







